import { Component, OnInit, HostListener, AfterViewInit, EventEmitter, Input, Output, SimpleChange, OnChanges, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { Draggable } from '../../assets/js/Draggable.js';
import { TweenLite } from '../../assets/js/TweenLite.js';
import { BasemapService } from '../basemap/basemap.service';
import { NgbModalConfig, NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import {
  trigger,
  state,
  style,
  animate,
  transition,
  group
  // ...
} from '@angular/animations';
import { FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { AuthObservableService } from '../Services/authObservableService';
import { ISlimScrollOptions, SlimScrollEvent } from 'ngx-slimscroll';
import { transformExtent } from 'ol/proj';
import { getCenter } from 'ol/extent';
import { BasemapFactory } from '../basemap/BasemapFactory.js';
import { get } from 'ol/proj';
import proj4 from 'proj4';
import { register } from 'ol/proj/proj4.js';
import OlView from 'ol/View';
import Stamen from 'ol/source/Stamen.js';
import OlTileLayer from 'ol/layer/Tile';
import OlXYZ from 'ol/source/XYZ';
import { CommonService } from '../Services/common.service';
import { GeotowerService } from '../geotower/geotower.service';
import { environment } from 'src/environments/environment';
// import { jsPDF } from 'jspdf';
import { extent } from 'ol/extent';
import * as jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { getPointResolution } from 'ol/proj';
import { fromExtent } from 'ol/geom/Polygon';
import Feature from 'ol/Feature';
import { OSM, Vector as VectorSource } from 'ol/source';
import ImageLayer from 'ol/layer/Image.js';
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer';
import { getTransform } from 'ol/proj';
import { applyTransform, getTopRight, getWidth, getHeight } from "ol/extent";
import { Observable, Subscription } from 'rxjs';
import { PrintToolService } from '../Services/print-tool.service.js';
import Overlay from 'ol/Overlay.js';
import { MyService } from '../Services/geobar.service.js';
import { HttpClient } from '@angular/common/http';
import GeoJSON from 'ol/format/GeoJSON.js';
import Point from 'ol/geom/Point.js';
// import { Fill, Stroke, Style, Text } from 'ol/style';
import CircleStyle from 'ol/style/Circle'
import JSON from 'ol/format/EsriJSON';
import { get as getProjection } from 'ol/proj';
import KML from 'ol/format/KML.js';
import tiles from 'ol/source/TileWMS';
import { GeobarService } from '../geobar/geobar.service'
import { ReturndataService } from '../Services/returndata.service'
import { GeobarComponent } from '../geobar/geobar.component.js';
import { Router } from '@angular/router';
import { GeobaseService } from '../Services/geobase.service';
import { GeoNotePadService } from '../Services/geo-notepad.service';
import { TopicsService } from '../Services/topics.service';
import {transform} from 'ol/proj'
import {getDistance} from 'ol/sphere';


// import {getCoordinateFromPixel} from 'ol/map';
import {Map, getPixelFromCoordinate} from 'ol/Map';
// import OSM from 'ol/source/OSM';
// import TileLayer from 'ol/layer/Tile';
import View from 'ol/View';
import OlMap from 'ol/Map';
import { Select } from 'ol/interaction';
import { map } from 'rxjs-compat/operator/map.js';
import { addLayer } from 'ol/Map';
import Layer from 'ol/layer/Layer';
import { Polygon } from 'ol/geom';
import { Vector } from 'ol/layer'
import { package_features_control as packagefeatures } from '../map-canvas/map-canvas.component'
import { boundingExtent } from 'ol/extent';
import { Fill, Stroke, Style, Text } from 'ol/style';
import { AngularFireStorage } from '@angular/fire/storage';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpClientService } from '../Services/http-client.service.js';
import { SetLayerTOMapUtil } from '../geotower/util/setLayerToMapUtil.js';
import { ChangeProjectionService } from '../Services/change-projecton.service.js';
import { TowerItemOptionsComponent } from '../geotower/tower-item-options/tower-item-options.component.js';
import { SearchTriggerService } from '../Services/search-trigger.service'
import { LayersService } from '../Services/layers.service.js';

var geoJSONFormat = new GeoJSON();
let siteVectorData = [] ;


@Component({
  selector: 'app-geosol',
  templateUrl: './geosol.component.html',
  styleUrls: ['./geosol.component.scss'],
  animations: [
    trigger('slideInOut', [
      state('in', style({ width: '*', opacity: 0 })),
      transition(':enter', [
        style({ width: '0', opacity: 0 }),

        group([
          animate(300, style({ width: '*' })),
          animate('400ms ease-in-out', style({ opacity: '1' }))
        ])

      ])
    ]),
    trigger('slideInOut-mid', [
      state('in', style({ width: '*', opacity: 0 })),
      transition(':enter', [
        style({ width: '0', opacity: 0 }),

        group([
          animate(300, style({ width: '*' })),
          animate('400ms ease-in-out', style({ opacity: '1' }))
        ])

      ])
    ])
  ],
  providers: [NgbModalConfig, NgbModal]
})
export class GeosolComponent implements OnInit, AfterViewInit, OnChanges {
  private basemapProjection;

  layerObj;
  Colors = ['#9400D3', '#4B0082', '#0000FF', '#00FF00', '#FFFF00', '#FF7F00', '#FF0000'];

  showCompassCtrl = '';
  closeCompassCtrl = '';
  topRightMenuToogle = false;
  closeResult: string;
  unitValue = '';
  unitsList: any = [
    {
      value: 'us',
      view: 'Miles',
      scaleValue: 'us'
    },
    {
      value: 'metric',
      view: 'Kms',
      scaleValue: 'metric'
    }/* ,
    {
      value: 'METERS',
      view: 'Meters',
    } */
  ];
  matToolTips = [
    { id: 1, tooltip: "Fires", src: "assets/svgs/geosol/observations/windy.png" },
    { id: 2, tooltip: "WATERSHEDS", src: "assets/svgs/geosol/observations/windy.png" },
    { id: 3, tooltip: "EV CHARGING STATIONS", src: "assets/svgs/geosol/observations/windy.png" },
  ]
  // unitCtrl:FormControl = new FormControl('');
  form: FormGroup;

  isLeftWingExpanded = true;
  isRightWingExpanded = true;
  mapProjectionUnits = '500 nm';
  mapProjectionUnitsPopup = '';
  innerWidth: number;
  orientationActive = false;
  iconWidth = 60; // 65;

  leftWingContainerWidth: string = ((this.iconWidth * 5) + 45) + 'px'; // '320px';
  rightWingContainerWidth: string = ((this.iconWidth * 5) + 45) + 'px'; // '320px';

  tcAndPrivacyActivePage = 'tc';
  opts: ISlimScrollOptions;
  scrollEvents: EventEmitter<SlimScrollEvent>;
  scaleLineWidth = 0;
  rotationAngle: FormControl = new FormControl(0);
  compassOpenState = false;
  selectedOption = '';
  frameworkForm: FormGroup;
  static mapReference;
  referenceSystemTypes: any[] = [
    { index: 1, name: 'EPSG:3857', value: '3857' },
    { index: 2, name: 'EPSG:4326', value: '4326' },
    { index: 3, name: 'EPSG:2100', value: '2100' },
    { index: 4, name: 'EPSG:27700', value: '27700' },
    { index: 5, name: 'EPSG:23032', value: '23032' },
    { index: 6, name: 'EPSG:5479', value: '5479' },
    { index: 7, name: 'EPSG:21781', value: '21781' },
    { index: 8, name: 'EPSG:3413', value: '3413' },
    { index: 9, name: 'EPSG:2163', value: '2163' },
    { index: 10, name: 'ESRI:54009', value: '54009' },
    { index: 11, name: 'EPSG:2229', value: '2229' },
    { index: 12, name: 'EPSG:4120', value: '4120' },
    { index: 13, name: 'EPSG:4470', value: '4470' }
  ];
  selectedReferenceSystem: any = this.referenceSystemTypes[0];
  @Input() awarenessCurrentMode: any = {};
  @Input() globalObject: any = {};
  @Output() toggleAwareness: EventEmitter<any> = new EventEmitter<any>();
  @Output() passInputValue: EventEmitter<any> = new EventEmitter<any>();
  // @Output() emitId: EventEmitter<any> = new EventEmitter<any>();
  lastClickHappend: number = new Date().getTime();
  @Input() isGuest = true;
  // showConceptSplashScreen = false;
  // showGeoReferencingScreen: boolean;
  // minimizedGeoRefWindow: boolean;
  @ViewChild('geoRefWindow') geoRefWindow: ElementRef<HTMLDivElement>;
  showTooltip = true;
  @Output() triggerToShowFeSpalsh: EventEmitter<any> = new EventEmitter<any>();

  activeSitesForPresentation: any[] = [];
  presetName:string;
  jsonData:any
  geojson:any
  inputUrl:any

  nav_history: any[] = [];
  size = -1;
  undo_redo :boolean=false;


  showFrameworkForm: boolean = false;
  testingepsgvalue: string;
  epsgCodee: any;
  finalProjection: any;
  latestlayername: any;
  select: any;
  wholeID: any;
  // epsgCodee: string;
  countFires = 0;
  countWater = 0;
  countEv = 0;
  countCageol = 0;
  countTopoMap = 0;
  randomIndex: any;
  isDisabled = false;
  isDisabled1 = false;
  isDisabled2 = false;
  isDisabled3 = false;
  isDisabled6 = false;
  isDisabled4 = false;
  isDisabled5 = false;
  isDisabled7 = false;
  isDisabled8 = false;
  showgeosolframeworkmap: boolean;
  showgeosolframeworkcs: boolean;
  showgeosolconcepts: boolean;
  showgeosolobservations: boolean;
  showgeosolapplications: boolean;
  showgeosolawarness: boolean;
  showgeosolproduction: boolean;
  showgeosolgeoreferencing: boolean;
  showgeosolanalysis: boolean;
  showgeosolmanagement: boolean;
  showgeosolpresentation1: boolean;
  showgeohubcompass: boolean;
  packagegeosol: string[];
  packagecontrolgeosol: string[];
  showgeosolframeworkcoordinatesys: any;
  packageEventsSubscription: Subscription;
  @Input() packageEvent; // Observable<any>;
  packageItems;

  constructor(private basemapService: BasemapService, config: NgbModalConfig, private modalService: NgbModal,
    private geotowerService: GeotowerService, private renderer: Renderer2,
    private commonService: CommonService, private formBuilder: FormBuilder,
    private observ: AuthObservableService, private printToolService: PrintToolService,
    private authObsr: AuthObservableService, private http: HttpClient, private geobarService: GeobarService, private returndataService: ReturndataService,
    private router: Router, private geoTowerService: GeotowerService,
    private geobaseService: GeobaseService, private notePadService: GeoNotePadService,
    private topicsService: TopicsService, private httpc: HttpClientService, private firestorage: AngularFireStorage,
    private layerService: LayersService, private sanitizer: DomSanitizer,
    private myService: MyService, private changeService: ChangeProjectionService, private triggerService: SearchTriggerService, private elementRef: ElementRef) {
    this.geotowerService.deleteIndex.subscribe(res => {
      console.log(res, "check always here for index data")
      if (res === '0') {
        this.isDisabled = false
      } else if (res === '1') {
        this.isDisabled1 = false
      } else if (res === '2') {
        this.isDisabled2 = false
      } else if (res === '3') {
        this.isDisabled3 = false
      } else if (res === '4') {
        this.isDisabled4 = false
      } else if (res === '5') {
        this.isDisabled5 = false
      } else if (res === '6') {
        this.isDisabled6 = false
      } else if (res === '1674569220274_cageol.kml') {
        this.isDisabled7 = false
      } else if (res === '8') {
        this.isDisabled8 = false
      }
    })
    //this.basemapProjection = this.basemapService.getCurrentBasemap().getView().getProjection().code_;
    config.backdrop = 'static';
    config.keyboard = false;
    this.opts = {
      position: 'right',
      barBackground: '#656565', // '#C9C9C9',
      barOpacity: '0.7',
      barWidth: '7', // '10',
      barBorderRadius: '5', // '20',
      barMargin: '0',
      gridBackground: '#D9D9D9',
      gridOpacity: '1',
      gridWidth: '2',
      gridBorderRadius: '20',
      gridMargin: '0',
      alwaysVisible: true,
      visibleTimeout: 1000,
      // scrollSensitivity: 1
    };

    this.form = this.formBuilder.group({
      unitCtrl: ['us']
    });
    this.frameworkForm = this.formBuilder.group({
      framework: new FormControl('rectangular')
    });
    this.frameworkForm.controls.framework.valueChanges.subscribe(res => {
      console.log(res);
      if (res === 'rectangular') {
        this.showangulardropdown = false;
      } else if (res === 'angular') {
        this.showrectangulardropdown = false;
      }
    });
    localStorage.setItem('unit', 'us');
    this.rotationAngle.valueChanges.subscribe(val => {
      console.log('ANGLE CHANGED');
      console.log(val);
      let rotationValue;
      if (this.commonService.isValid(val) && val !== '-' && val !== '+') {
        rotationValue = val;
        console.log(Math.PI / 180 * rotationValue);
        this.basemapService.getCurrentBasemap().getView().setRotation(Math.PI / 180 * rotationValue);
      } else {
        rotationValue = 0;
        this.basemapService.getCurrentBasemap().getView().setRotation(Math.PI / 180 * 0);
      }
    });
    this.observ.subscribeForAuthStatus('GeosolComponent', (status, msg) => {
      console.log('LOGIN STATUS CHANGED');
      console.log(status);
      console.log(msg);
      if (status.status === 'success') {
        this.isGuest = false;
        this.closeTooltip();
      } else if (status.status === 'failed') {
        this.isGuest = true;
      }
    });
  }

  isValid(str): any {
    if (str == null || str === undefined || str === ' ' || str === '' || str === 'null' || str === 'undefined') {
      return false;
    } else { return true; }
  }
  @HostListener('window:keyup.esc', ['$event'])
  escapeKeyPressed(event: KeyboardEvent): any {
    console.log('esc clicked, geotray', event);
    this.resetSelectedOption();
    this.showFrameworkForm = false;
  }
  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.showFrameworkForm = false;
      this.resetSelectedOption();

    }
  }
  resetSelectedOption(): void {
    this.selectedOption = '';
    this.showFrameworkForm = false;

  }
  selectGeosolOption(currOption): void {
    console.log('clicked the geosol buttons ', this.isGuest, currOption);

    /* if(this.isGuest || this.isGuest === undefined) {
      if(currOption === 'presentation' || currOption === 'analysis' || currOption === 'management'
      || currOption === 'production' || currOption === 'referencing'||
      currOption === 'observations' || currOption === 'applications' || currOption === 'awareness'
      || currOption === 'frameworks' || currOption === 'concepts') {
        this.observ.initiateAuthenticationRequest({from: 'geosol'});
        return;
      }
    } */
    let showAwareness = false;
    let isPreviousOptionAwareness = false;
    if (currOption === this.selectedOption) {
      this.resetSelectedOption();
      showAwareness = false;
    } else {
      if (this.selectedOption === 'awareness') {
        isPreviousOptionAwareness = true;
      }
      this.selectedOption = currOption;
      showAwareness = true;
      this.showFrameworkForm = false;

    }
    if (currOption === 'awareness') {
      const viewMode = {
        mode: 'awareness',
        show: showAwareness,
        timestamp: new Date().getTime(),
        from: 'awareness',
        op: ''
      };
      this.toggleAwareness.emit(viewMode);
    } else if (isPreviousOptionAwareness) {
      const viewMode = {
        mode: 'awareness',
        show: false,
        timestamp: new Date().getTime(),
        from: 'awareness',
        op: ''
      };
      this.toggleAwareness.emit(viewMode);
    }
    if (this.selectedOption === 'referencing') {
      console.log('GEO REF ENABLED');
      this.observ.updateGeorefToggleStatus(true);
      this.showFrameworkForm = false;

    } else {
      console.log('GEO REF DISABLED');
      // this.observ.updateGeorefToggleStatus(false);
    }
  }
  activatePackageUIElements(): any {
    if(this.packageItems !== null) {
      if(!this.showgeosolframeworkmap || !this.showgeosolframeworkcoordinatesys || !this.showgeosolconcepts
        || !this.showgeosolobservations || !this.showgeosolapplications || !this.showgeosolgeoreferencing
        || !this.showgeosolpresentation1) {
          this.packageItems.forEach((key, value) => {
            if (key.name == "GeosolFramework" && !this.showgeosolframeworkmap) {
              this.showgeosolframeworkmap = key.isAccess;
            }
            if (key.name == "GeosolFrameworkcoordinatesys" && !this.showgeosolframeworkcoordinatesys) {
              this.showgeosolframeworkcoordinatesys = key.isAccess;
            }
            if (key.name == "GeosolConccepts" && !this.showgeosolconcepts) {
              this.showgeosolconcepts = key.isAccess;
            }
            if (key.name == "GeosolObservations" && !this.showgeosolobservations) {
              this.showgeosolobservations = key.isAccess;
            }
            if (key.name == "GeosolApplications" && !this.showgeosolapplications) {
              this.showgeosolapplications = key.isAccess;
            }
            if (key.name == "GeosolAwarness" && !this.showgeosolawarness) {
              this.showgeosolawarness = key.isAccess;
            }
            if (key.name == "GeosolGeoreferencing" && !this.showgeosolgeoreferencing) {
              this.showgeosolgeoreferencing = key.isAccess;
            }
            if (key.name == "GeosolProduction" && !this.showgeosolproduction) {
              this.showgeosolproduction = key.isAccess;
            }
            if (key.name == "GeosolManagement" && !this.showgeosolmanagement) {
              this.showgeosolmanagement = key.isAccess;
            }
            if (key.name == "GeosolAnalysis" && !this.showgeosolpresentation1) {
              this.showgeosolpresentation1 = key.isAccess;
            }
            if (key.name == "showgeosolPresentation" && !this.showgeosolobservations) {
              this.showgeosolobservations = key.isAccess;
            }
            if (key.name == "GeosolCompass" && !this.showgeohubcompass) {
              this.showgeohubcompass = key.isAccess;
            }
          })
      }    
    }
  }
  ngOnChanges(changes: { [key: string]: SimpleChange }): any {
    if (this.commonService.isValid(changes.awarenessCurrentMode)) {
      if (this.commonService.isValid(changes.awarenessCurrentMode.currentValue)) {
        console.log(this.awarenessCurrentMode);
        console.log(this);
        if (this.selectedOption === 'awareness') {
          if (this.awarenessCurrentMode.mode === 'capture' || !this.awarenessCurrentMode.show) {
            this.selectedOption = '';
          }
        }
      }
    }
    if(localStorage.getItem('email') !== null) {
      if(this.packageItems === null || this.packageItems === undefined) {
        this.packageItems = this.commonService.packageStringToJsonArray();
        this.activatePackageUIElements();    
      }
    }
    // this.packageEventsSubscription = this.packageEvent.subscribe((data) => {
      // console.log('************ package on geosol data is ', localStorage.getItem('packageItems'))
      // const packageItems = JSON.parse(localStorage.getItem('packageItems'));
      // // const packageItems = data.package_features_control.default.package.features;
      // console.log('************ package on geosol data is' , packageItems)
      // packageItems.forEach((key, value) => {
      //   if (key.name == "GeosolFramework" && !this.showgeosolframeworkmap) {
      //     this.showgeosolframeworkmap = key.isAccess;
      //   }
      //   if (key.name == "GeosolFrameworkcoordinatesys" && !this.showgeosolframeworkcoordinatesys) {
      //     this.showgeosolframeworkcoordinatesys = key.isAccess;
      //   }
      //   if (key.name == "GeosolConccepts" && !this.showgeosolconcepts) {
      //     this.showgeosolconcepts = key.isAccess;
      //   }
      //   if (key.name == "GeosolObservations" && !this.showgeosolobservations) {
      //     this.showgeosolobservations = key.isAccess;
      //   }
      //   if (key.name == "GeosolApplications" && !this.showgeosolapplications) {
      //     this.showgeosolapplications = key.isAccess;
      //   }
      //   if (key.name == "GeosolAwarness" && !this.showgeosolawarness) {
      //     this.showgeosolawarness = key.isAccess;
      //   }
      //   if (key.name == "GeosolGeoreferencing" && !this.showgeosolgeoreferencing) {
      //     this.showgeosolgeoreferencing = key.isAccess;
      //   }
      //   if (key.name == "GeosolProduction" && !this.showgeosolproduction) {
      //     this.showgeosolproduction = key.isAccess;
      //   }
      //   if (key.name == "GeosolManagement" && !this.showgeosolmanagement) {
      //     this.showgeosolmanagement = key.isAccess;
      //   }
      //   if (key.name == "GeosolAnalysis" && !this.showgeosolpresentation1) {
      //     this.showgeosolpresentation1 = key.isAccess;
      //   }
      //   if (key.name == "showgeosolPresentation" && !this.showgeosolobservations) {
      //     this.showgeosolobservations = key.isAccess;
      //   }
      //   if (key.name == "GeosolCompass" && !this.showgeohubcompass) {
      //     this.showgeohubcompass = key.isAccess;
      //   }
      // })
    // });
  }
  ngOnInit(): any {
    // this.packageEventsSubscription = this.packageEvent.subscribe((data) => {
    //   console.log('************ package on geosol data is ', data, data.package_features_control.default.package.features)
    //   const packageItems = JSON.parse(localStorage.getItem('packageItems'));
    //   // const packageItems = data.package_features_control.default.package.features;
    //   console.log(packageItems)
    //   packageItems.forEach((key, value) => {
    //     if (key.name == "GeosolFramework" && !this.showgeosolframeworkmap) {
    //       this.showgeosolframeworkmap = key.isAccess;
    //     }
    //     if (key.name == "GeosolFrameworkcoordinatesys" && !this.showgeosolframeworkcoordinatesys) {
    //       this.showgeosolframeworkcoordinatesys = key.isAccess;
    //     }
    //     if (key.name == "GeosolConccepts" && !this.showgeosolconcepts) {
    //       this.showgeosolconcepts = key.isAccess;
    //     }
    //     if (key.name == "GeosolObservations" && !this.showgeosolobservations) {
    //       this.showgeosolobservations = key.isAccess;
    //     }

    //     if (key.name == "GeosolApplications" && !this.showgeosolapplications) {
    //       this.showgeosolapplications = key.isAccess;
    //     }
    //     if (key.name == "GeosolAwarness" && !this.showgeosolawarness) {
    //       this.showgeosolawarness = key.isAccess;
    //     }
    //     if (key.name == "GeosolGeoreferencing" && !this.showgeosolgeoreferencing) {
    //       this.showgeosolgeoreferencing = key.isAccess;
    //     }
    //     if (key.name == "GeosolProduction" && !this.showgeosolproduction) {
    //       this.showgeosolproduction = key.isAccess;
    //     }
    //     if (key.name == "GeosolManagement" && !this.showgeosolmanagement) {
    //       this.showgeosolmanagement = key.isAccess;
    //     }
    //     if (key.name == "GeosolAnalysis" && !this.showgeosolpresentation1) {
    //       this.showgeosolpresentation1 = key.isAccess;
    //     }
    //     if (key.name == "showgeosolPresentation" && !this.showgeosolobservations) {
    //       this.showgeosolobservations = key.isAccess;
    //     }
    //     if (key.name == "GeosolCompass" && !this.showgeohubcompass) {
    //       this.showgeohubcompass = key.isAccess;
    //     }
    //   })
    // });
    setTimeout(() => {
      for (let i = 0; i < packagefeatures["default"]["package"]["features"].length; i++) {
        if (packagefeatures["default"]["package"]["features"][i]["name"] == "GeosolFramework") {
          console.log(packagefeatures["default"]["package"]["features"][i]["isAccess"],);
          this.showgeosolframeworkmap = (packagefeatures["default"]["package"]["features"][i]["isAccess"]);
        }
        if (packagefeatures["default"]["package"]["features"][i]["name"] == "GeosolFrameworkcoordinatesys") {
          console.log(packagefeatures["default"]["package"]["features"][i]["isAccess"],);
          this.showgeosolframeworkcoordinatesys = (packagefeatures["default"]["package"]["features"][i]["isAccess"]);
        }
        if (packagefeatures["default"]["package"]["features"][i]["name"] == "GeosolConccepts") {
          console.log(packagefeatures["default"]["package"]["features"][i]["isAccess"],)
          this.showgeosolconcepts = (packagefeatures["default"]["package"]["features"][i]["isAccess"])
        }
        if (packagefeatures["default"]["package"]["features"][i]["name"] == "GeosolObservations") {
          console.log(packagefeatures["default"]["package"]["features"][i]["isAccess"],)
          this.showgeosolobservations = (packagefeatures["default"]["package"]["features"][i]["isAccess"])
        }
        if (packagefeatures["default"]["package"]["features"][i]["name"] == "GeosolApplications") {
          console.log(packagefeatures["default"]["package"]["features"][i]["isAccess"],)
          this.showgeosolapplications = (packagefeatures["default"]["package"]["features"][i]["isAccess"])
        }
        if (packagefeatures["default"]["package"]["features"][i]["name"] == "GeosolAwarness") {
          console.log(packagefeatures["default"]["package"]["features"][i]["isAccess"],);
          this.showgeosolawarness = (packagefeatures["default"]["package"]["features"][i]["isAccess"]);
        }
        if (packagefeatures["default"]["package"]["features"][i]["name"] == "GeosolGeoreferencing") {
          console.log(packagefeatures["default"]["package"]["features"][i]["isAccess"],)
          this.showgeosolgeoreferencing = (packagefeatures["default"]["package"]["features"][i]["isAccess"])
        }
        if (packagefeatures["default"]["package"]["features"][i]["name"] == "GeosolProduction") {
          console.log(packagefeatures["default"]["package"]["features"][i]["isAccess"],)
          this.showgeosolproduction = (packagefeatures["default"]["package"]["features"][i]["isAccess"])
        }
        if (packagefeatures["default"]["package"]["features"][i]["name"] == "GeosolManagement") {
          console.log(packagefeatures["default"]["package"]["features"][i]["isAccess"],)
          this.showgeosolmanagement = (packagefeatures["default"]["package"]["features"][i]["isAccess"])
        }
        if (packagefeatures["default"]["package"]["features"][i]["name"] == "GeosolAnalysis") {
          console.log(packagefeatures["default"]["package"]["features"][i]["isAccess"],)
          this.showgeosolanalysis = (packagefeatures["default"]["package"]["features"][i]["isAccess"])
        }
        if (packagefeatures["default"]["package"]["features"][i]["name"] == "showgeosolPresentation") { // GeosolPresentation
          console.log(packagefeatures["default"]["package"]["features"][i]["isAccess"],)
          this.showgeosolpresentation1 = (packagefeatures["default"]["package"]["features"][i]["isAccess"])
        }
        if (packagefeatures["default"]["package"]["features"][i]["name"] == "GeosolCompass") {
          console.log(packagefeatures["default"]["package"]["features"][i]["isAccess"],)
          this.showgeohubcompass = (packagefeatures["default"]["package"]["features"][i]["isAccess"])
        }
      }
    }, 7000);
    this.presetData(this.presetName, null);
    console.log(this);
    this.basemapService.onLoadScaleLine.subscribe((element) => {
      console.log('trigged when added layer or zooming ', element);
      this.getMapProjectionUnits();
      this.getScaleLineWidth();
    });
    this.getMapProjectionUnits();
    this.getScaleLineWidth();
    this.basemapService.getCurrentBasemap().on('moveend', (e) => {
      // console.log(e);
      this.getMapProjectionUnits();
      // this.basemapService.setLoadScaleLine();
      var baseMap = this.basemapService.getCurrentBasemap();
      if(this.undo_redo===false){
        if(this.size<this.nav_history.length-1){				
          for(var i=this.nav_history.length-1;i>this.size;i--){
            console.log(this.nav_history.pop());
          }
        }
        this.nav_history.push({extent:baseMap.getView().calculateExtent(baseMap.getSize()),size:baseMap.getSize(), zoom:baseMap.getView().getZoom()});			
        console.log("size1:",this.size);
        this.size=this.size+1;
        console.log("Nav History:",this.nav_history);
        console.log("size2:",this.size);
      }
    });
    Draggable.create('#rotationImgID', {
      type: 'rotation',
      throwProps: true,
      // bounds: { minRotation: -23, maxRotation: 337 },
      onDrag: (e) => {
        // console.log(e);
        let target = null;
        if (e.target.tagName === 'SPAN') {
          target = e.target.parentNode || e.target.parentElement;
        } else if (e.target.id === 'rotationImgID') {
          target = e.target;
        } else {
          console.log('OTHER ELEMENT');
        }
        if (this.isValid(target)) {
          // console.log('VALID TARGET...');
          const element = target; // e.target;
          // console.log(element);
          // console.log(element._gsTransform);
          let angle = element._gsTransform.rotation;
          // console.log(e, angle, element);
          // Here code call for setting the angle to base map
          angle = angle + 23;
          this._setRotation(angle);
        } else {
          // console.log('INVALID TARGET...');
        }
      },
      onDragEnd: (e) => {
        let target = null;
        if (e.target.tagName === 'SPAN') {
          target = e.target.parentNode || e.target.parentElement;
        } else if (e.target.id === 'rotationImgID') {
          target = e.target;
        } else {
          console.log('OTHER ELEMENT');
        }
        if (this.isValid(target)) {
          // console.log('VALID TARGET...');
          const element = target; // e.target;
          let angle = element._gsTransform.rotation;
          console.log(angle, element);
          // Here code call for setting the angle to base map
          angle = angle + 23;
          this._setRotation(angle);
        } else {
          // console.log('INVALID TARGET...');
        }
      }
    });
    try {
      this._updateDraggableObj();
    } catch (e) {
      console.log(e);
    }
    this.form.controls.unitCtrl.valueChanges.subscribe(val => {
      console.log('Unit value changed', this.form.controls.unitCtrl.value);
      console.log(val, "check the value in unitctrl");
      this.submit();
    });
    this.innerWidth = window.innerWidth;

    this.basemapService.onLoadOrientation.subscribe(rotationValue => {
      console.log('Rotation value from Tray tool ', rotationValue);
      try {
        const globeIconDraggable = Draggable.get('#rotationImgID');
        TweenLite.set('#rotationImgID', { rotation: rotationValue - 23 });
        globeIconDraggable.update();
      } catch (e) {
        console.log(e);
      }
    });
    setTimeout(() => {
      this.observ.updateReferenceSystem(this.selectedReferenceSystem);
    }, 1000);
    this.packagecontrolgeosol = ["GeosolFramework", "GeosolConcepts", "GeosolObservations", "GeosolApplications", "GeosolAwarness", "GeosolGeoreferencing", "GeosolProduction",
      "GeosolManagement", "GeosolAnalysis", "showgeosolPresentation", "GeosolCompass"];




  }

  ngAfterViewInit(): any {
    setTimeout(() => {
      this.orientationActive = true;
    }, 1000);
    this.closeTooltip();
  }

  setDefaultPosition(event): any {
    this._updateDraggableObj();
    this._setRotation(0);
  }

  private _updateDraggableObj(): any {
    const globeIconDraggable = Draggable.get('#rotationImgID');
    TweenLite.set('#rotationImgID', { rotation: -23 });
    globeIconDraggable.update();
  }

  private _setRotation(rotationValue): any {
    this.basemapService.isOrientationEvent = true;
    this.basemapService.getCurrentBasemap().getView().setRotation(Math.PI / 180 * rotationValue);
    this.basemapService.isOrientationEvent = false;
    this.rotationAngle.setValue(rotationValue);
  }

  toogleTopRightMenu(event): any {
    this.topRightMenuToogle = !this.topRightMenuToogle;
  }

  loadDefaultLayers(): any {
    this.basemapService.setLoadDeafultLayers();
  }
  openMeasureUnitsChangePopup(event, content): any {
    console.log('openMeasureUnitsChangePopup');
    console.log(event);
    /* this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.resetSelectedOption();
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    }); */
  }
  showBlog(): void {
    // this.showConceptSplashScreen = true;    
    this.triggerToShowFeSpalsh.emit(true);
    // if (this.showgeosolconcepts == true) {
    //   console.log('IN SHOW BLOG');
    //   this.triggerToShowFeSpalsh.emit(true);
    //   // window.open('https://mapsolgeo.com/fe/home.html', '_blank');
    // }
    // else {
    //   alert("Subscribe for a higer package to use this feature")
    // }
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  valueChanged(event): any {
    console.log('valueChanged');
    console.log(event);
  }
  submit(): any {
    this.basemapService.getCurrentBasemap().controls.forEach(control => {
      console.log('what is control here ', control);
      if (control.values_ !== null && control.values_.units !== undefined) {
        this.basemapService.getCurrentBasemap().removeControl(control);
        control.setUnits(this.form.controls.unitCtrl.value);
        // this.mapProjectionUnits = this.setMapProjectionUnits(control.renderedHTML_);
        this.mapProjectionUnits = this.setMapProjectionUnits(control.element.innerText);
        this.basemapService.getCurrentBasemap().addControl(control);
      }
    });
    this.observ.updateUnits(this.form.controls.unitCtrl.value);
    // this.modalService.dismissAll('close');
    localStorage.setItem('unit', this.form.controls.unitCtrl.value);
  }
  getScaleLineWidth(): any {
    setTimeout(() => {
      try {
        const mapControlCollection: any[] = this.basemapService.getCurrentBasemap().getControls().array_;
        // console.log(mapControlCollection);
        mapControlCollection.forEach(element => {
          // console.log(element);
          // console.log(element.renderedWidth_);
          if (this.isValid(element.renderedWidth_)) {
            this.scaleLineWidth = element.renderedWidth_;
          }
        });
        console.log('SCALE LINE WIDTH : ', this.scaleLineWidth);
      } catch (e) {
        console.log(e);
      }
    }, 1000);
  }
  zoomIn(): any {
    // console.log(this.basemapService.getCurrentBasemap().getView());
    // console.log(this.basemapService.getCurrentBasemap().getView().getZoom());
    const currentZoom = this.basemapService.getCurrentBasemap().getView().getZoom();
    const maxZoom = this.basemapService.getCurrentBasemap().getView().getMaxZoom();
    this.getScaleLineWidth();
    if (currentZoom < maxZoom) {
      this.basemapService.getCurrentBasemap().getView().setZoom(this.basemapService.getCurrentBasemap().getView().getZoom() + 1);
      this.getMapProjectionUnits();
    }
  }
  zoomOut(): any {
    const currentZoom = this.basemapService.getCurrentBasemap().getView().getZoom();
    const minZoom = this.basemapService.getCurrentBasemap().getView().getMinZoom();
    this.getScaleLineWidth();
    if (currentZoom > minZoom) {
      this.basemapService.getCurrentBasemap().getView().setZoom(this.basemapService.getCurrentBasemap().getView().getZoom() - 1);
      this.getMapProjectionUnits();
    }
  }

  previousExtent(): any{
    console.log('previous extent');
    var baseMap = this.basemapService.getCurrentBasemap();
    if(this.size>0){			
      this.undo_redo=true;						
      baseMap.getView().fit(this.nav_history[this.size-1].extent, this.nav_history[this.size-1].size);
      baseMap.getView().setZoom(this.nav_history[this.size-1].zoom);			
      setTimeout(function () {
        this.undo_redo = false;
      }, 360);
      this.size=this.size-1;
      console.log(this.size);
    }
  }

  nextExtent(): any{
    console.log('next extent');
    var baseMap = this.basemapService.getCurrentBasemap();
    if(this.size<this.nav_history.length-1){			
      this.undo_redo=true;						
      baseMap.getView().fit(this.nav_history[this.size+1].extent, this.nav_history[this.size+1].size);
      baseMap.getView().setZoom(this.nav_history[this.size+1].zoom);			
      setTimeout(function () {
        this.undo_redo = false;
      }, 360);
      this.size=this.size+1;
      console.log(this.size);
    }
    // if(this.size<this.nav_history.length-1){			
    //   this.undo_redo=true;						
    //   baseMap.getView().fit(this.nav_history[this.size+1].extent, this.nav_history[this.size+1].size);
    //   baseMap.getView().setZoom(this.nav_history[this.size+1].zoom);			
    //   // setTimeout(function () {
    //   this.undo_redo = false;
    //   // }, 360);
    //   this.size=this.size+1;
    //   console.log(this.size);
    // }
  }
  getMapProjectionUnits(): any {
    this.basemapService.getCurrentBasemap().controls.forEach(control => {
      if (this.commonService.isValid(control.values_)) {
        if (control.values_.units !== undefined) {
          setTimeout(() => {
            // console.log('Here scal line ', control, control.renderedHTML_, control.element.innerText);
            this.mapProjectionUnits = this.setMapProjectionUnits(control.element.innerText);
          }, 1000);
        }
      }
    });
  }
  setMapProjectionUnits(val): any {
    const tempArr = val.split(' ');
    if (tempArr.length > 1) {
      // Here adding new code for view factory related
      const scaleLine = tempArr[3].match(/\d+/g);
      const scalByHalf = Number(scaleLine) / 2;
      const value = tempArr[2].slice(0, tempArr[2].length - (scalByHalf.toString().length)).slice(0, -1); // .replace(/\,/g, '');
      console.log(tempArr, ' :: ', scaleLine, ' : ', value);
      const viewFactory = tempArr[0] + tempArr[1] + value;
      this.observ.updateViewFactory(viewFactory);
      if (scaleLine.includes('.')) {
        let fixedNum = scaleLine.substr(scaleLine.indexOf('.') + 1).length;
        // console.log(fixedNum);
        if (fixedNum > 5) {
          // console.log('MORE THAN 5. SETTING TO 5');
          fixedNum = 5;
        }
        return String(Number(scaleLine).toFixed(fixedNum)) + ' ' + tempArr[4];
      } else {
        return scaleLine + ' ' + tempArr[4];
      }
    } else {
      return val;
    }
  }
  @HostListener('window:resize', ['$event'])
  onResize(event): any {
    // console.log(event);
    // console.log(this);
    this.innerWidth = window.innerWidth;
  }
  toggleOptions(from = 'all'): any {
    // console.log(new Date().getTime() - this.lastClickHappend);
    if (new Date().getTime() - this.lastClickHappend > 500) {
      // console.log('CLICKED A WHILE AGO...');
    } else {
      // console.log('EARLY CLICK...');
      return;
    }
    this.lastClickHappend = new Date().getTime();
    if (from === 'all') {
      if (this.isLeftWingExpanded || this.isRightWingExpanded) {
        this.isLeftWingExpanded = this.isRightWingExpanded = false;
        setTimeout(() => {
          this.leftWingContainerWidth = '0px';
          this.rightWingContainerWidth = '0px';
        }, 500);
      } else {
        this.leftWingContainerWidth = ((this.iconWidth * 5) + 45) + 'px'; // '320px';
        this.rightWingContainerWidth = ((this.iconWidth * 5) + 45) + 'px'; // '320px';
        setTimeout(() => {
          this.isLeftWingExpanded = this.isRightWingExpanded = true;
        }, 100);
      }
    } else if (from === 'left') {
      // this.isLeftWingExpanded=!this.isLeftWingExpanded;
      if (this.isLeftWingExpanded) {
        this.resetSelectedOption();
        this.isLeftWingExpanded = false;
        setTimeout(() => {
          this.leftWingContainerWidth = '0px';
        }, 500);
      } else {
        this.leftWingContainerWidth = ((this.iconWidth * 5) + 45) + 'px'; // '320px';
        setTimeout(() => {
          this.isLeftWingExpanded = true;
        }, 100);
      }

    } else if (from === 'right') {
      if (this.isRightWingExpanded) {
        this.isRightWingExpanded = false;
        setTimeout(() => {
          this.rightWingContainerWidth = '0px';
        }, 500);
      } else {
        this.rightWingContainerWidth = ((this.iconWidth * 5) + 45) + 'px'; // '320px';
        setTimeout(() => {
          this.isRightWingExpanded = true;
        }, 100);
      }
    }
  }
  showAppInfo(event, content): any {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.resetSelectedOption();
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  reprojectSiteData():any{
    if(siteVectorData.length>0){
      siteVectorData.forEach(siteNote=>{
        this.reprojectLocationOnMap(siteNote)
      });
    }
  }

  reprojectLocationOnMap(notes: any, operation = '', zoomToSite = true): any {
    
    console.log('In reprojectLocationOnMap clicked on Location ',  notes, operation);
    // console.log(note);
    let geometryData: any;
    let isPolygon = false;
    let isMultiPolygon = false;
    const note = notes.site

    const siteParams = note.siteParams;
    var data;

    if(!note.ismultigeometry){
      if (note.latitudeLongitude.length === 2) {
        let ptCoords = this.basemapService.getTransformedCoordinates([Number(note.latitudeLongitude[0]), Number(note.latitudeLongitude[1])], 'EPSG:4326', this.basemapService.getCurrentBasemap().getView().getProjection().getCode())
        geometryData = {
          type: this.notePadService.shapeDrawType.POINT, // 'Point',
          coordinates: ptCoords
          // coordinates: [Number(note.latitudeLongitude[0]), Number(note.latitudeLongitude[1])]
        };
      } else if (note.latitudeLongitude.length > 2) {
        if (note.latitudeLongitude.length > 4) {
          // console.log(`LAT : ${note.latitudeLongitude[0]} === ${note.latitudeLongitude[note.latitudeLongitude.length - 2]} `);
          // console.log(`LONG : ${note.latitudeLongitude[1]} === ${note.latitudeLongitude[note.latitudeLongitude.length - 1]} `);
          if (note.latitudeLongitude[0] === note.latitudeLongitude[note.latitudeLongitude.length - 2] &&
              note.latitudeLongitude[1] === note.latitudeLongitude[note.latitudeLongitude.length - 1]) {
              isPolygon = true;
          }
        }
        geometryData = {
          type: isPolygon ? this.notePadService.shapeDrawType.POLYGON : this.notePadService.shapeDrawType.LINE_STRING,
          coordinates: []
        };
        let i = 0;
        const coOrds = [];
        while ( i < note.latitudeLongitude.length ) {
          try{
            const tempArray = [Number(note.latitudeLongitude[i]), Number(note.latitudeLongitude[i + 1])];
            coOrds.push(tempArray);
          } catch (e) {
            console.log(e);
          }
          i = i + 2;
        }
        
        console.log('coOrds:',coOrds)
        let coords_trans = coOrds.map(coord =>this.basemapService.getTransformedCoordinates(coord, 'EPSG:4326', this.basemapService.getCurrentBasemap().getView().getProjection().getCode()))
        console.log('coords_trans:',coords_trans)
        geometryData.coordinates = isPolygon ? [coords_trans] : coords_trans;
  
      }
      const id = this.commonService.isValid(note.observationInstanceId) ? note.observationInstanceId :note.latitudeLongitude.toString();
      // console.log('what is this.currentContextInfo ', this.currentContextInfo);
      data = {
        features: {
          type: 'FeatureCollection',
          features: [{
            type: 'Feature',
            geometry: geometryData,
            properties: {'' : note.description + '\n' + new Date(note.uiTimestamp).toLocaleString()}
          }]
        },
        name: this.commonService.isValid(note.observationInstanceId) ? `${note.locationName}_${id}` :
                                `observationInstanceId_${id}`
      };
      let visible = false;
        if (note.latitudeLongitude.length === 2) {
          console.log('ADDING POINT');
          this.notePadService.reDrawPointOrPolygonOnMap(this.notePadService.shapeDrawType.POINT,
                                                      data, operation !== 'awareness' && zoomToSite, null, notes, siteParams);
          visible = true;
        } else if (note.latitudeLongitude.length > 2) {
          console.log('ADDING POLYGON');
          // this.notePadService.reDrawPolygonInMap(data, true);
  
          // LINE or PLOYGON is ok here.
          this.notePadService.reDrawPointOrPolygonOnMap(
                                isPolygon ? this.notePadService.shapeDrawType.POLYGON : this.notePadService.shapeDrawType.LINE_STRING,
                                data, operation !== 'awareness' && zoomToSite, null, notes, siteParams);
          visible = true;
        }
    }
    else{
      if(Array.isArray(note.multiGeometryLatitudeLongitude[0])) {
        if(note.multiGeometryLatitudeLongitude[0].length == 2){
          // note.siteParams.siteType = 'MultiPoint';
          // note.siteType = 'MultiPoint';
          const id = this.commonService.isValid(note.observationInstanceId) ? note.observationInstanceId :note.latitudeLongitude.toString();
          data = {
              features: {
              type: 'FeatureCollection',
              features: []
            },
            name: /*`${note.locationName}_${id}` /*/ `observationInstanceId_${id}`
          };
          let i=0;
          while ( i < note.multiGeometryLatitudeLongitude.length ) {
            const latlngs = note.multiGeometryLatitudeLongitude[i]
            let ptCoords = this.basemapService.getTransformedCoordinates([Number(latlngs[0]),Number(latlngs[1])],'EPSG:4326', this.basemapService.getCurrentBasemap().getView().getProjection().getCode())
            var featureData = {
              type:'Feature',
              geometry:{
                type: this.notePadService.shapeDrawType.MULTI_POINT, // 'multiPoint',
                coordinates: [ptCoords]
                // coordinates: [note.multiGeometryLatitudeLongitude[i]]
              },
              properties: null
            }
            data.features.features.push(featureData)
            i = i + 1;
          };
          // 
        }else{
          if (note.multiGeometryLatitudeLongitude[0].length > 2) {
            if (note.multiGeometryLatitudeLongitude[0].length > 4) {
              // console.log(`LAT : ${note.latitudeLongitude[0]} === ${note.latitudeLongitude[note.latitudeLongitude.length - 2]} `);
              // console.log(`LONG : ${note.latitudeLongitude[1]} === ${note.latitudeLongitude[note.latitudeLongitude.length - 1]} `);
              if (note.multiGeometryLatitudeLongitude[0][0] === note.multiGeometryLatitudeLongitude[0][note.multiGeometryLatitudeLongitude[0].length - 2] &&
                  note.multiGeometryLatitudeLongitude[0][1] === note.multiGeometryLatitudeLongitude[0][note.multiGeometryLatitudeLongitude[0].length - 1]) {
                    isMultiPolygon = true;
              }
            }
            const id = this.commonService.isValid(note.observationInstanceId) ? note.observationInstanceId :note.latitudeLongitude.toString();
            data = {
                features: {
                type: 'FeatureCollection',
                features: []
              },
              name: /*`${note.locationName}_${id}` /*/ `observationInstanceId_${id}`
            };
            let i=0;
            // let j = 0;
            while ( i < note.multiGeometryLatitudeLongitude.length ) {
              const coOrds = [];
              let j = 0;
              while ( j < note.multiGeometryLatitudeLongitude[i].length ) {
                try{
                  const tempArray = [Number(note.multiGeometryLatitudeLongitude[i][j]), Number(note.multiGeometryLatitudeLongitude[i][j + 1])];
                  coOrds.push(tempArray);
                } catch (e) {
                  console.log(e);
                }
                j = j + 2;
              }
              console.log('coOrds:',coOrds)
              let coords_trans = coOrds.map(coord =>this.basemapService.getTransformedCoordinates(coord, 'EPSG:4326', this.basemapService.getCurrentBasemap().getView().getProjection().getCode()))
              if(isMultiPolygon){
                featureData = {
                  type:'Feature',
                  geometry:{
                    type: this.notePadService.shapeDrawType.MULTI_POLYGON,
                    // coordinates: ptCoords
                    coordinates: [[coords_trans]]
                  },
                  properties: null
                }
              }else{
                featureData = {
                  type:'Feature',
                  geometry:{
                    type: this.notePadService.shapeDrawType.MULTI_LINE,
                    // coordinates: ptCoords
                    coordinates: [coords_trans]
                  },
                  properties: null
                }
              }
              
              data.features.features.push(featureData)
              i = i + 1;
            };
            // type = isMultiPolygon ? this.notePadService.shapeDrawType.MULTI_POLYGON : this.notePadService.shapeDrawType.MULTI_LINE;
          }
        } 
      }
      let visible = false;
      if (note.multiGeometryLatitudeLongitude[0].length == 2) {
        console.log('ADDING MULTIPOINT');
        this.notePadService.reDrawPointOrPolygonOnMap(this.notePadService.shapeDrawType.MULTI_POINT,
                                                    data, operation !== 'awareness' && zoomToSite, null, notes, siteParams);
        visible = true;
      }else{
        this.notePadService.reDrawPointOrPolygonOnMap(
          isMultiPolygon ? this.notePadService.shapeDrawType.MULTI_POLYGON : this.notePadService.shapeDrawType.MULTI_LINE,
          data, operation !== 'awareness' && zoomToSite, null, notes, siteParams);
      }
    }
    
      // // // Here need to add tags Locations
      note.observationTagsList.forEach(tag => {
        this.basemapService.getCurrentBasemap().getLayers().forEach(layerObj => {
          if (layerObj !== undefined) {
            if (layerObj.values_.name === data.name) {
              console.log(layerObj.values_.name, layerObj, data, tag)
              // this.nodePadService.tagLocationsAddToMap(tag, layerObj);
              const labelStyle = new Style({
                text: new Text({
                  font: '13px Calibri,sans-serif',
                  scale: 1.5,
                  fill: new Fill({
                    color: 'rgba(0, 0, 0, 1)'
                  }),
                  padding: [2, 2, 2, 2],
                  stroke: new Stroke({
                        color: "#000f",
                        width: 1
                    })
                })
              });              
            labelStyle.getText().setText(tag.tagName)
            let tagCoords = this.basemapService.getTransformedCoordinates([tag.latitude,tag.longitude], 'EPSG:4326', this.basemapService.getCurrentBasemap().getView().getProjection().getCode())
            const point = new Point(tagCoords);
            // layerObj.getSource().getFeatures()[0].labelPoint = point;
            const pointFeature = new Feature({
              geometry: point,
              // projection: newProj
              name: tag.tagName,
            });
            pointFeature.setStyle(labelStyle)
            layerObj.getSource().addFeature(pointFeature)
            }
          }
        });
      });
    // }
  }

  changeView(epsgCodee, bbox, epsgCode): any {
    const newProj = get(epsgCodee); // .setExtent(extent_);
    let view = this.basemapService.getCurrentBasemap().getView();
    const fromLonLat = getTransform("EPSG:4326", newProj);

    let worldExtent = [bbox[1], bbox[2], bbox[3], bbox[0]];

    if (bbox[1] > bbox[3]) {
      worldExtent = [bbox[1], bbox[2], bbox[3] + 360, bbox[0]];
    }

    const extent = applyTransform(worldExtent, fromLonLat, undefined, 8);
    const transformedBbox = transformExtent(worldExtent, "EPSG:4326", epsgCodee);
    const topRight = getTopRight(extent)
    const center = getCenter(extent)
    const width = getWidth(extent);
    const height = getHeight(extent);

// Get the distance between the two coordinates, in meters
var distance = getDistance(topRight, center);

// // Get the resolution of the map at the point of interest
var resolution = this.basemapService.getCurrentBasemap().getView().getResolution();

// // Convert the distance to pixels
var pixels = distance / resolution;

// // Set the offset of the text to the calculated pixel distance
// textOffsetX = pixels;
// textOffsetY = -pixels;
// const pixelCoord= OlMap.getPixelFromCoordinate(extent)

    console.log(width, height, topRight, extent,pixels, center, 'offset x and y')
    // newProj.setExtent();
    console.log(extent, worldExtent, 'hello')
    // newProj.setExtent(extent);
    // const coordinates = boundingExtent(bbox); //no need this
    const polygon = fromExtent(extent);
    const polygonFeature = new Feature({
      geometry: polygon,
      // projection: newProj
    });

    const vectorLayer = new VectorLayer({
      source: new VectorSource({
        features: [polygonFeature]
      }),
      name: 'boundingLayer',
    });
    polygonFeature.setStyle(new Style({
      stroke: new Stroke({
        color: '#FF0000', // please use color codes
        width: 1
      }),

    }));
    var point = new Point(topRight);
    const pointFeature = new Feature({
      geometry: point,
      // projection: newProj
    });
    var textLayer = new VectorLayer({
      source: new VectorSource({
        features: [pointFeature]
      }),
      name: 'textLayer',
      style: new Style({
        text: new Text({
          text: this.epsgCodee,
          font: '13px Calibri,sans-serif',
          textAlign: 'right',
          fill: new Fill({
            color: 'rgba(255, 255, 255, 1)'
          }),
          padding: [2, 2, 2, 2],
          backgroundFill: new Fill({
            color: 'rgba(0, 0, 0, 1)'
          }),
          backgroundStroke: new Stroke({
            color: 'rgba(0, 0, 0, 1)',
            width: 1
          }),
          offsetX: -4,
          offsetY: 8,
        })
      }),
      maxResolution: 15000
    });
    
    // Add the text layer to the map
  //   polygonFeature.getStyle().getText().setPlacement(topRight)
  //  console.log(polygonFeature.getStyle().getText(), 'positiontext') 
    //  const styleText = vectorLayer.values_.source.featuresRtree_.items_[94].value.style_
    //  console.log(styleText, 'styledtext')

    //   // const styleText = vectorLayer.
    //   // console.log(styleText, 'styletext')

    //   styleText.setText( new Text({
    //         text: this.epsgCodee,
    //       font: '13px Calibri,sans-serif',
    //       textAlign: 'left',
    //       justify: 'left',
    //       textBaseline: 'top',
    //   }))
    const overlay = new Overlay({
      element: vectorLayer.features,
      positioning: 'top-right',
      position: [10, 10]

    })
    //  layers = new Layer({ 
    //   layers: [],
    // });
    view = new OlView({
      projection: newProj,
      // minZoom: 10
      // center: [latVal, lngVal],

    })
    this.basemapService.getCurrentBasemap().getInteractions().forEach(interaction => {
      if (interaction instanceof Select) {
        // Deactivate the Select interaction
        interaction.setActive(false);
        // Remove the Select interaction from the vector layer's interactions
        this.basemapService.getCurrentBasemap().getInteractions().remove(interaction);
      }
    });

    console.log('vectorlayer is', vectorLayer);

    // const buffer = extent.buffer(extent, 0.2 *extent.getWidth(extent));
    const layers = this.basemapService.getCurrentBasemap().getLayers().getArray();
    console.log(polygonFeature, 'polygon feature')
    const sourceCode = this.basemapService.getCurrentBasemap().getView().getProjection().code_;
    this.basemapService.getCurrentBasemap().setView(view);
    view.fit(extent);
    console.log(epsgCode, 'epsgCode')
    // this.reprojectSiteData()
    if (epsgCode !== '3857' && epsgCode !== '4326') {
      this.basemapService.getCurrentBasemap().setView(view);
      // view.fit(extent);
      this.basemapService.getCurrentBasemap().addLayer(vectorLayer);
      this.basemapService.getCurrentBasemap().addLayer(textLayer);
      this.basemapService.getCurrentBasemap().getView().setZoom(this.basemapService.getCurrentBasemap().getView().getZoom() - 1);
      console.log(this.basemapService.getCurrentBasemap().removeInteraction(), 'interactions')

      // this.basemapService.getCurrentBasemap().addOverlay(overlay);
      const vectorMapLayers = layers.filter((layer) => layer instanceof VectorLayer);
      console.log('vectormaplayers', vectorMapLayers)
      const vectorLayersToRemove = vectorMapLayers.slice(0, -2);
      vectorLayersToRemove.forEach((layer) => {
        if (layer.values_.name == 'boundingLayer' || layer.values_.name == 'textLayer' ) {
          this.triggerService.setData(epsgCode)
          this.basemapService.getCurrentBasemap().removeLayer(layer)
        }
      });
      // const resolution = this.basemapService.getCurrentBasemap().getLayers().getResolution();
      // console.log(resolution, 'resolution')

//       const textLayersToRemove = vectorMapLayers.filter(layer => layer.values_.name == 'textLayer');
// textLayersToRemove.forEach(layer => {
//   this.basemapService.getCurrentBasemap().removeLayer(layer);
// });
      // vectorLayersToRemove.forEach((layer) => {
      //   if (layer.values_.name == 'textLayer' ) {
      //     this.triggerService.setData(epsgCode)
      //     this.basemapService.getCurrentBasemap().removeLayer(layer)
      //   }
      // });
    }
    if (epsgCode == '3857' || epsgCode == '4326') {
      console.log(this.globalObject, 'what is that EPSG HERE ', epsgCode, this.epsgCodee,  this.basemapService.getCurrentBasemap().getView().getProjection().code_)
      // const sourceCode = this.basemapService.getCurrentBasemap().getView().getProjection().code_;
      // this.basemapService.getCurrentBasemap().setView(view);
      const destCode = this.epsgCodee;
      console.log(sourceCode, destCode)
      const results = this.userLocation();
      console.log('what is the Error Here? ', results);
      if (results === undefined) {
        const longitude = -119.417931; // 78.433237;
        const latitude = 36.778259; // 17.661594;
        if(epsgCode == '3857') {
          this.basemapService.getCurrentBasemap().getView().setCenter(transform([longitude, latitude],'EPSG:4326',this.epsgCodee));
        } else {
          this.basemapService.getCurrentBasemap().getView().setCenter([longitude, latitude]);
        }
        this.basemapService.getCurrentBasemap().getView().setZoom(4);
      }
      const vectorMapLayers = layers.filter((layer) => layer instanceof VectorLayer);
      console.log('vectormaplayers', vectorMapLayers)
      // const vectorLayersToRemove = vectorMapLayers.slice(0, -1);
      vectorMapLayers.forEach((layer) => {
        if (layer.values_.name == 'boundingLayer' || layer.values_.name == 'textLayer' ) {
          this.triggerService.setData(epsgCode)
          this.basemapService.getCurrentBasemap().removeLayer(layer)
        }
      });
    }

    // layers.forEach((layer, index) => {
    //   if (layer instanceof VectorLayer && index < layers.getLength() - 1 && layer === vectorLayer1) {
    //     this.basemapService.getCurrentBasemap().removeLayer(layer);
    //   }
    // });
    this.finalProjection = this.basemapService.getCurrentBasemap().getView().getProjection();
    GeosolComponent.mapReference = this.finalProjection
    var layersCount = this.basemapService.getCurrentBasemap().getLayers()
    this.basemapService.getCurrentBasemap().getLayers().forEach(currentLayer => {
      console.log('layers name', currentLayer.values_.name)
      for (let i = 0; i < layersCount.length; i++) {
        // if(layersCount[i].name)
        console.log(layersCount[i].name, 'layerscount')
      }
      for (let i = 0; i < this.geotowerService.geotowerLayersList.length; i++) {
        console.log(this.geotowerService.geotowerLayersList.length, 'geotowerlayer length')
        this.latestlayername = this.geotowerService.geotowerLayersList[i].name
        console.log(this.latestlayername, 'geotower latestlayer')
        console.log(currentLayer, 'geotower layer values name')
        if (currentLayer.values_.name === this.latestlayername) {
          console.log('layers name', currentLayer.values_.name)
          console.log('layers list', this.geotowerService.geotowerLayersList)
          this.geotowerService.geotowerLayersList.forEach(layerObject => {
            if (layerObject.name == currentLayer.values_.name) {
              let previewLayer = layerObject.previewLayer;
              const options = {
                previewIsActive: previewLayer,
                layerObj: layerObject,
              };
              this.geotowerService.activateEvent(options, 'DisplayLayer')

              console.log('layer object', layerObject.name)
              // }, 1000);             

            }

          })

          
          // const options = { layerObj: currentLayer, geotower: this };
          // this.geotowerService.activateEvent(options, 'DisplayLayer')
        }

      }

      for (let i = 0; i < this.geotowerService.clientObjList.length; i++) {
        console.log(this.geotowerService.clientObjList.length, 'client length')
        this.latestlayername = this.geotowerService.clientObjList[i].name
        console.log(this.latestlayername, 'latestlayer')
        console.log(currentLayer, 'layer values name')
        if (currentLayer.values_.name === this.latestlayername) {
          console.log('layers name', currentLayer.values_.name)
          console.log('layers list', this.geotowerService.clientObjList)
          console.log('got to the sanat layer', currentLayer)
          this.geotowerService.clientObjList.forEach(layerObject => {
            //       if(layerObject.name== currentLayer.values_.name){
            //       }
            if (layerObject.name == currentLayer.values_.name) {
              this.basemapService.getCurrentBasemap().removeLayer(layerObject)
              TowerItemOptionsComponent.deleteLayer(layerObject, {}, this.layerService, this.geotowerService, this.basemapService, true)
              // setTimeout(() => {
              console.log('layer object', layerObject.name)
              console.log('layer of geotower', this.geotowerService.clientObjList)
              const options = { layerObj: layerObject, geotower: this };

              this.geotowerService.activateEvent(options, 'LayerSetToMap')

              console.log('layer object', layerObject.name)
              // }, 1000);             

            }

          })
          const options = { layerObj: currentLayer, geotower: this };
          this.geotowerService.activateEvent(options, 'LayerSetToMap')
        }

      }


      // const vectorLayers = layers.filter((layer) => layer instanceof VectorLayer);
      // console.log(vectorLayers, 'layeredvector')
      // const vectorLayersRemove = vectorLayers.slice(0, -1);
      // console.log(this.geotowerService.clientObjList, 'layerofvaluesofname')

      // vectorLayersRemove.forEach((layer) => {
      //   for (let i = 0; i < this.geotowerService.clientObjList.length; i++) {
      //     console.log(this.geotowerService.clientObjList, 'layerofvaluesofname')

      //     if
      //       (layer.values_.name == this.geotowerService.clientObjList[i].name) {
      //         this.basemapService.getCurrentBasemap().removeLayer(layer)
      //       console.log(layer.values_.name, 'layerofvaluesofname')
      //     }
      //     console.log(this.geotowerService.clientObjList, 'layerofvaluesofname')
      //   }
      // });
    }
    );
    console.log(this.geotowerService.clientObjList, 'layerofvaluesofname')
    console.log(GeosolComponent.mapReference, 'maprefe')
    console.log(this.finalProjection, 'finalpojection')
    // this.observ.updateMapReferenceSystem(this.finalProjection)
    // const previousVectorLayers = layers.getArray().slice(1, -1);
    // previousVectorLayers.forEach(layer => this.basemapService.getCurrentBasemap().removeLayer(layer));
    // this.basemapService.getCurrentBasemap().addLayer(vectorLayer)
    console.log('vectorlayers are', this.basemapService.getCurrentBasemap().getLayers());

    console.log('projection is', this.basemapService.getCurrentBasemap().getView().getProjection());
    // console.log('source is', vectorLayer.getSource());
    console.log('epsgCodee is', this.epsgCodee);

    const vectorMapLayers = layers.filter((layer) => layer instanceof VectorLayer);
    // console.log('vectormaplayers', vectorMapLayers)
    // const vectorLayersToRemove = vectorMapLayers.slice(0, -1);|| lyr.className_ == 'clipped'
    vectorMapLayers.forEach((lyr) => {
      if (lyr.values_['geopadCustomData'] ){
        this.basemapService.getCurrentBasemap().removeLayer(lyr)
      }
    });
    if(siteVectorData.length > 0){
      this.reprojectSiteData()
    }    
    siteVectorData = [];
  }

  //   private _createClientLayerJsonObj(clientLayerObj): any {
  //     return {
  //       name: clientLayerObj.fileName,
  //       isServer: false,
  //       maximized: false,
  //       previewLayer: true,
  //       files: clientLayerObj.inputFiles,
  //       fileType: clientLayerObj.filetype,
  //       zipfile: clientLayerObj.zipfile,
  //       metadata: clientLayerObj.metadata,
  //       firebaseUrl: clientLayerObj.firebaseUrl,
  //       proj: clientLayerObj.proj,
  //       timestamp: new Date(),
  //       orderNumber: this.layersList.length + 1
  // };
  //   }
  radioButtonState: boolean = false;

  showrectangulardropdown: boolean = false;

  openrectangulardropdown() {
    this.showrectangulardropdown = !this.showrectangulardropdown;
  }

  showangulardropdown: boolean = false;

  openangulardropdown() {
    this.showangulardropdown = !this.showangulardropdown;
  }
  // Need to change to common service - rajini
  userLocation() {
    if (navigator.geolocation) {
      console.log('changed- navigated true ');
      return navigator.geolocation.getCurrentPosition(this.updateLocation, this.handleLocationError, { timeout: 5000 });
    } else {
      console.log('Browser is so old');
    }
  }
  updateLocation(positionData) {
    console.log('changed- user location ', positionData);
    var transformed_Coordinates = transform([positionData.coords.longitude,positionData.coords.latitude],'EPSG:4326','EPSG:3857')
    console.log(transformed_Coordinates, 'tarnsformedcoordinates')
    const latitude = transformed_Coordinates[1];
    const longitude = transformed_Coordinates[0];
    this.basemapService.getCurrentBasemap().getView().setCenter([longitude, latitude]);
  }
  handleLocationError() {
    console.log('Browser block the location permission!!!');
  }

  angularepsg = 'EPSG:4326';
  rectangularepsg = 'EPSG:3857';

  projectionChangeEvent(epsgCode, id): any {
    this.wholeID = id
    console.log(epsgCode, id, "check code")
    // this.passInputValue.emit(`${epsgCode}`);
    let layers = this.basemapService.getCurrentBasemap().getLayers().array_;
    layers.forEach((lyr) => {
      if (lyr.values_['geopadCustomData']){
        console.log('true',lyr.values_['geopadCustomData'].site)
        siteVectorData.push(lyr.values_['geopadCustomData'])
        // this.basemapService.getCurrentBasemap().removeLayer(lyr)
      }
    });

    this.epsgCodee = 'EPSG:' + epsgCode;
    // this.commonService.sendData(this.epsgCodee)

    //  this.myService.trigger()

    this.showrectangulardropdown = false;
    this.showangulardropdown = false;
    console.log(id, epsgCode, 'checkid');


    // this.finalProjection= this.basemapService.getCurrentBasemap().getView().getProjection();
    console.log('selected epsgCode ', this.epsgCodee);
    if (id === 'angular') {
      this.angularepsg = this.epsgCodee;
    } else if (id === 'rectangular') {
      this.rectangularepsg = this.epsgCodee;
    }
    const index = this.referenceSystemTypes.findIndex(
      (refSys) => String(refSys.value) === String(epsgCode)
    );
    if (index !== -1) {
      this.selectedReferenceSystem = this.referenceSystemTypes[index];
      this.observ.updateReferenceSystem(this.selectedReferenceSystem);
    }
    this.getProjDef(this.epsgCodee).subscribe(result => {
      proj4.defs(this.epsgCodee, result.proj4);
      register(proj4);
      this.changeView(this.epsgCodee, result.bbox, epsgCode);
      // console.log(projdef, 'projdef')
      console.log('find bbox ', result.bbox);
      console.log(
        this.selectedReferenceSystem,
        'selected epsg projection value'
      );
      this.triggerService.setData(epsgCode)
    });
    // this.emitId.emit(id);
    // this.reprojectSiteData();

  }
  private getProjDef(epsgCodee): Observable<any> {
    let projdef = '+proj=laea +lat_0=45 +lon_0=-100 +x_0=0 +y_0=0 ' +
      '+a=6370997 +b=6370997 +units=m +no_defs';
    return new Observable(observer => {
      
      fetch('https://epsg.io/?format=json&q=' + this.epsgCodee.split(':')[1])
        .then((response) => {
          return response.json().then((jsonData) => {
            const results = jsonData.results;
            console.log('getting proj4 result ', results);
            console.log('find epsgcodee in getProjdef ', epsgCodee);
            // console.log('find projdef in getprojdef before results ', projdef);
            if (results && results.length > 0) {
              // console.log('find projdef in getprojdef after results ', projdef);
              for (let i = 0, ii = results.length; i < ii; i++) {
                const result = results[i];
                console.log('result of i ', result);
                if (result) {
                  const code = result.code;
                  const name = result.name;
                  const proj4def = result.proj4;
                  console.log('find proj4def ', proj4def);
                  const bbox = result.bbox;
                  console.log('hi guys', result.code)

                  // if(result.code=='3857'||result.code=='4326'){
                  //   observer.next(proj4def)
                  //   console.log('hi guys' )
                  //   return
                  // }
                  if (proj4def && proj4def.length > 0)
                    observer.next(result);
                  console.log('find bbox ', bbox);
                  observer.complete();
                  return;
                }
              }
            } else {
              this.epsgCodee = 'NO-EPSG';
              console.log('no result for epsg ', this.epsgCodee);
            }
            observer.next(projdef);
            observer.complete();
          });
        });
    });
  }


  presetData(presetName, index) {
    this.randomIndex = index

    if (presetName === 'Vegetation_Fires') {
      if (!this.isDisabled) {
        this.inputUrl = 'https://services.gis.ca.gov/arcgis/rest/services/Environment/Vegetation_Fires/MapServer?f=pjson'
        this.validateUrl(this.inputUrl, presetName)
        this.isDisabled = true
      }
    } else if (presetName === 'Watersheds') {
      if (!this.isDisabled1) {
        this.inputUrl = 'https://services.gis.ca.gov/arcgis/rest/services/Environment/Watersheds/MapServer?f=pjson'
        this.validateUrl(this.inputUrl, presetName)
        this.isDisabled1 = true
      }

    } else if (presetName === 'LIQ') {
      if (!this.isDisabled2) {
        this.inputUrl = 'https://gis.conservation.ca.gov/server/rest/services/CGS_Earthquake_Hazard_Zones/SHP_Liquefaction_Zones/FeatureServer?f=pjson'
        this.validateUrl(this.inputUrl, presetName)
        this.isDisabled2 = true
      }

    } else if (presetName === 'EV stations') {
      if (!this.isDisabled6) {
        this.inputUrl = 'https://developer.nrel.gov/api/alt-fuel-stations/v1.geojson?api_key=DEMO_KEY&fuel_type=ELEC&state=CA'
        this.validateUrl(this.inputUrl, presetName)
        this.isDisabled6 = true;
      }

    } else if (presetName === 'HISTORIC SEISMICITY-3.5') {
      if (!this.isDisabled4) {
        this.inputUrl = 'https://gis.conservation.ca.gov/server/rest/services/CGS/Historic_Earthquakes_M3_5Plus/FeatureServer?f=pjson'
        this.validateUrl(this.inputUrl, presetName)
        this.isDisabled4 = true
      }

    } else if (presetName === 'HISTORIC SEISMICITY-5.5') {
      if (!this.isDisabled5) {
        this.inputUrl = 'https://gis.conservation.ca.gov/server/rest/services/CGS/Historic_Earthquakes_M5_5Plus/FeatureServer?f=pjson'
        this.validateUrl(this.inputUrl, presetName)
        this.isDisabled5 = true
      }

    } else if (presetName === 'FAULT ZONES') {
      if (!this.isDisabled3) {
        this.inputUrl = 'https://gis.conservation.ca.gov/server/rest/services/CGS_Earthquake_Hazard_Zones/SHP_Fault_Zones/FeatureServer?f=pjson'
        this.validateUrl(this.inputUrl, presetName)
        this.isDisabled3 = true
      }

    } else if (presetName === 'Cageol') {
      if (!this.isDisabled7) {
        this.inputUrl = ''
        this.validateUrl(this.inputUrl, presetName)
        this.isDisabled7 = true
      }
    } else if (presetName === 'USGSWFS') {
      if (!this.isDisabled8) {
        // this.inputUrl = ''
        // this.validateUrl(this.inputUrl, presetName)
        const wfsURL = 'https://geoserver.fuse.earth/geoserver/tiger/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=tiger%3AUSGS_24KBoundaries&outputFormat=application%2Fjson';
        var vectorSource = new VectorSource({
          format: new GeoJSON(),
          url: wfsURL,
          // params: {
          //   service:"WFS",
          //   version:"1.0.0",
          //   request:"GetFeature",
          //   typeName:"tiger:USGS_24KBoundaries",
          //   outputFormat:"application/json"
          // }
          // strategy: ol.loadingstrategy.bbox
        });
    
        var vector = new VectorLayer({
          source: vectorSource,
          style: new Style({
            stroke: new Stroke({
              color: 'rgba(0, 0, 255, 1.0)',
              width: 2
            })
          }),
          name:'USGS_24KBoundaries',
          zIndex:5
        });
        this.basemapService.getCurrentBasemap().addLayer(vector);
        this.isDisabled8 = true;
        const returnData = {
          firebaseUrl: wfsURL,
          filetype: 'url',
          metadata: [],
          fileName: 'USGS_24KBoundaries',
        };
        console.log("check if it is added to geotower from geohub ", returnData)
        this.basemapService.setLayerToGeotower(returnData);
      }
    }

  }

  validateUrl(endPoint, presetName) {
    console.log(endPoint, presetName, "check values of preset names")
    let fetchURL = endPoint;
    let isGeojsonServiceURL = false;
    if (presetName === 'Vegetation_Fires') {
      fetch(fetchURL).then((response) => {
        console.log('results in line 137 ', response);
        return response.json();
      }).then((jsonResults) => {
        console.log(jsonResults, "check the json results")
        console.log('response json and list of layers ', jsonResults.layers);
        console.log(jsonResults.layers.length, "check the layers length")
        if (jsonResults.layers !== undefined) {
          if (jsonResults.layers.length > 0) {
            /* jsonResults.layers.forEach(urlLayer => {
              fetchURL = fetchURL.split('?')[0] + '/' + urlLayer.id + '/query?f=pjson&where=1%3D1';
              this.fetchURLProcess(fetchURL, false, options);
            }); */
            fetchURL = fetchURL.split('?')[0] + '/' + jsonResults.layers[0].id + '/query?f=pjson&where=1%3D1';
            console.log(fetchURL, endPoint, "check fetch url....")
            this.fetchURLProcess(fetchURL, false, endPoint);
          }
        } else if (jsonResults.name !== null) {
          fetchURL = fetchURL.split('?')[0] + '/query?f=pjson&where=1%3D1';
          this.fetchURLProcess(fetchURL, false, endPoint);
        }
      })
      // fetchURL = fetchURL.split('?')[0] + '/' + jsonResults.layers[0].id + '/query?f=pjson&where=1%3D1';
      // console.log("Iam in fires")
      // console.log(fetchURL,"check fetch url")
      // this.fetchURLProcess(fetchURL, false, endPoint);
    } else if (presetName === 'Watersheds') {
      fetch(fetchURL).then((response) => {
        console.log('results in line 137 ', response);
        return response.json();
      }).then((jsonResults) => {
        console.log(jsonResults, "check the json results")
        console.log('response json and list of layers.... ', jsonResults.layers);
        console.log(jsonResults.layers.length, "check the layers length")
        if (jsonResults.layers !== undefined) {
          if (jsonResults.layers.length > 0) {
            /* jsonResults.layers.forEach(urlLayer => {
              fetchURL = fetchURL.split('?')[0] + '/' + urlLayer.id + '/query?f=pjson&where=1%3D1';
              this.fetchURLProcess(fetchURL, false, options);
            }); */
            fetchURL = fetchURL.split('?')[0] + '/' + jsonResults.layers[1].id + '/query?f=pjson&where=1%3D1';
            console.log(fetchURL, endPoint, "check fetch url....")
            this.fetchURLProcess(fetchURL, false, endPoint);
          }
        } else if (jsonResults.name !== null) {

          fetchURL = fetchURL.split('?')[0] + '/query?f=pjson&where=1%3D1';
          this.fetchURLProcess(fetchURL, false, endPoint);

        }
      })

      // fetchURL = fetchURL.split('?')[0] + '/' + jsonResults.layers[1].id + '/query?f=pjson&where=1%3D1';
      // console.log(fetchURL,"ccgjjk")
      // this.fetchURLProcess(fetchURL, false, endPoint);
    } else if (endPoint.indexOf('.geojson') > 0) {
      // condition-3: is input url have only geojson like arcgis hub
      const fetchURL = endPoint;
      console.log('its inside geojson');
      isGeojsonServiceURL = true;
      this.fetchURLProcess(fetchURL, true, endPoint);

      console.log(fetchURL, endPoint, "check fetch url....")

      // }else if(presetName === 'Cageol'){
      //   const fetchURL = endPoint;
      //   this.kmlImagePreview(fetchURL)
      //   //this.geotowerService.activateEvent(options, 'LayerSetToMap');
      // }else if(presetName === 'TopoMap'){
      //   fetchURL = endPoint;
      //   //fetch(fetchURL)=> response
      //   this.kmlImagePreview(fetchURL)
    } else if (presetName === 'LIQ') {
      fetch(fetchURL).then((response) => {
        console.log('results in line 880 ', response);
        return response.json();
      }).then((jsonResults) => {
        console.log(jsonResults, "check the json results")
        console.log('response json and list of layers ', jsonResults.layers);
        console.log(jsonResults.layers.length, "check the layers length")
        if (jsonResults.layers !== undefined) {
          if (jsonResults.layers.length > 0) {
            /* jsonResults.layers.forEach(urlLayer => {
              fetchURL = fetchURL.split('?')[0] + '/' + urlLayer.id + '/query?f=pjson&where=1%3D1';
              this.fetchURLProcess(fetchURL, false, options);
            }); */
            fetchURL = fetchURL.split('?')[0] + '/' + jsonResults.layers[0].id + '/query?f=pjson&where=1%3D1';
            console.log(fetchURL, endPoint, "check fetch url....")
            this.fetchURLProcess(fetchURL, false, endPoint);
          }
        } else if (jsonResults.name !== null) {

          fetchURL = fetchURL.split('?')[0] + '/query?f=pjson&where=1%3D1';
          this.fetchURLProcess(fetchURL, false, endPoint);

        }
      })

    } else if (presetName === 'HISTORIC SEISMICITY-3.5' || presetName === 'HISTORIC SEISMICITY-5.5' || presetName === 'FAULT ZONES') {
      fetch(fetchURL).then((response) => {
        console.log('results in line 137 ', response);
        return response.json();
      }).then((jsonResults) => {
        console.log(jsonResults, "check the json results")
        console.log('response json and list of layers.... ', jsonResults.layers);
        console.log(jsonResults.layers.length, "check the layers length")
        if (jsonResults.layers !== undefined) {
          if (jsonResults.layers.length > 0) {
            /* jsonResults.layers.forEach(urlLayer => {
              fetchURL = fetchURL.split('?')[0] + '/' + urlLayer.id + '/query?f=pjson&where=1%3D1';
              this.fetchURLProcess(fetchURL, false, options);
            }); */
            fetchURL = fetchURL.split('?')[0] + '/' + jsonResults.layers[0].id + '/query?f=pjson&where=1%3D1';
            console.log(fetchURL, endPoint, "check fetch url....")
            this.fetchURLProcess(fetchURL, false, endPoint);
          }
        } else if (jsonResults.name !== null) {
          fetchURL = fetchURL.split('?')[0] + '/query?f=pjson&where=1%3D1';
          this.fetchURLProcess(fetchURL, false, endPoint);
        }
      })
    } else if (presetName === 'Cageol') {
      var optionsFromService = this.returndataService.getFileData()
      const eventOptions = {
        fileUrls: optionsFromService,
        zip_file: zip,
        fileEvent: 'event',
        geobar: null,
        // geobar: new GeobarComponent(this.basemapService, this.renderer, this.router, this.authObsr,
        //   this.commonService, this.geobarService, this.geoTowerService, this.geobaseService,
        //   this.notePadService, this.topicsService, this.httpc, this.firestorage, this.basemapService,
        //   this.layerService, this.sanitizer, this.formBuilder, this.observ)
      };
      this.geobarService.activateEvent(eventOptions, 'Preset')
    }
  }


  private fetchURLProcess(fetchURL, isGeojson, endPoint): any {
    console.log(fetchURL, isGeojson, endPoint, "check valuesss...")
    console.log('IN fetchURLProcess');
    console.log(fetchURL);
    console.log(isGeojson);
    fetch(fetchURL).then((response) => {
      console.log('results after fetch', response);
      return response.json();
    }).then((featureCollection) => {
      console.log('results in 281', featureCollection);
      if (featureCollection.error !== undefined) {
        console.log('error find');
        // this._getAlertMessage(options.geobar.alertComponent);
        // this._getAlertMessage(options.geobar.alertComponent);
        //const alertMessage = featureCollection.error.code + ' ' + featureCollection.error.message;
        // const alert = options.geobar.alertComponent;
        // alert.setAlertMessage(alertMessage);
        // this.ngProgress.ref().complete();
        // this.hideLayerUploadStatus();
        // this.setUploadStatusInPercentage(100);
        return;
      }
      const arrayJson = [];
      const randomNumber = Math.floor(Math.random() * (999 - 100)) + 100;
      if (featureCollection.fileName !== undefined && featureCollection.fileName !== null) {
      } else {
        featureCollection.fileName = 'urlLayer_' + this.randomIndex;
        console.log(featureCollection.fileName, "check random name")
      }
      arrayJson.push(featureCollection);
      console.log(featureCollection, arrayJson, "check featurecollection")
      console.log("check if the layer preview is hit")
      this._layerPreview(featureCollection, isGeojson);
      const returnData = {
        firebaseUrl: fetchURL,
        filetype: 'url',
        metadata: arrayJson,
        fileName: featureCollection.fileName,
      };
      console.log("check if it is added to geotower", returnData)
      this.basemapService.setLayerToGeotower(returnData);
    })
  }
  private _layerPreview(geoJsonMapshaper, isGeojson): any {
    console.log("confirm the layerpreview hit")
    console.log('IN _layerPreview', geoJsonMapshaper);
    console.log(geoJsonMapshaper);
    console.log(isGeojson, "check true or false");
    if (geoJsonMapshaper.length > 0) {
      geoJsonMapshaper.forEach((jsonObj) => {
        console.log("btbrbrb 1")
        this._setLayerToMap(jsonObj, isGeojson);
      });
    } else {
      console.log("chebrgtbrbck 2")
      this._setLayerToMap(geoJsonMapshaper, isGeojson);
    }
  }

  private _setLayerToMap(geoJson, isGeojson): any {
    console.log("i am inside setlayertomap")
    console.log('IN _setLayerToMap');
    console.log(geoJson, "check if the data is geojson");
    console.log(isGeojson, "is GeoJson or not?");
    let featuresTypes = new GeoJSON();
    if (!isGeojson) {
      featuresTypes = new JSON();
    }
    const vectorSource = new VectorSource({
      features: (featuresTypes).readFeatures(geoJson, {
        featureProjection: `${this.basemapService.getCurrentBasemap().getView().getProjection().getCode()}`
      })
    });
    console.log(this.basemapProjection, "check basemapProjection")
    console.log(geoJson, "check geojson after adding to vector source")
    // new code for coloring
    const fill = new Fill({
      color: 'rgba(255, 255, 255, 1)'
    });
    const stroke = new Stroke({
      // color: '#319FD3',
      color: this.randomRainbowColor(0, 6),
      width: 1
    });
    const vectorLayer = new VectorLayer({
      source: vectorSource,
      /* style: this.getShapeStyle() */
      style: new Style({
        image: new CircleStyle({
          fill,
          stroke,
          radius: 5
        }),
        fill,
        stroke,
      }),
    });
    var dataProjection = vectorSource.getProjection();
    console.log(dataProjection, "check vector projection")
    console.log("hit 1")
    vectorLayer.set('name', geoJson.fileName);
    console.log(vectorLayer, "check the vector layer")
    console.log("hit 2")

    this.basemapService.getCurrentBasemap().addLayer(vectorLayer);
    // new code for zoom to extent
    console.log(this.basemapService.getCurrentBasemap().getLayers(), "check the vector layer.....all");
    this.basemapService.getCurrentBasemap().getLayers().forEach(currentLayer => {
      console.log("i am oustide matched", geoJson.fileName, currentLayer.values_.name)
      if (geoJson.fileName === currentLayer.values_.name) {
        console.log("i am inide matched")
        const extentValue = currentLayer.values_.source.getExtent();
        console.log('what is value of  extent ', extentValue);
        this.basemapService.getCurrentBasemap().getView().fit(extentValue);
        this.basemapService.getCurrentBasemap().getView().setZoom(this.basemapService.getCurrentBasemap().getView().getZoom() - 1);
      }
    });
  }

  showCompass(): any {

    if (this.showgeohubcompass == true) {
      console.log('In showCompass');
      console.log(this.compassOpenState);
      if (!this.compassOpenState) {
        this.compassOpenState = true;
        // this.showCompassCtrl.emit(new Date().getTime());
        this.showCompassCtrl = String(new Date().getTime());
      } else {
        this.compassOpenState = false;
        // this.closeCompassCtrl.emit(new Date().getTime());
        this.closeCompassCtrl = String(new Date().getTime());
      }
    }
    else {
      alert("Subscribe for a higher package to use this feature")
    }
  }
  compassClosedEventFun(): void {
    this.compassOpenState = false;
  }
  displayFullMap(): any {
    if (this.showgeohubcompass == true) {
      console.log('In displayFullMap');
      this.basemapService.getCurrentBasemap().getView().setZoom(2);
    }
    else {
      alert("Subscribe for a higher package to use this feature")
    }
  }
  closeTooltip(): void {
    if (!this.isGuest) {
      setTimeout(() => {
        this.showTooltip = false;
      }, environment.feUserGuideTooltipAutoCloseDuration);
    }
  }
  openFrameworkForm(): any {
    this.showFrameworkForm = !this.showFrameworkForm;
  }
  private kmlImagePreview(kmlURL): any {
    const fileName = 'earthPointTopoMap_225741.kml'
    console.log(kmlURL, "check the kml url")
    // fetch(kmlURL).then(response => response.json(
    //  )).then(data => console.log(data,"check the kml response"))
    const vectorLayer = new VectorLayer({
      source: new VectorSource({
        url: kmlURL,
        format: new KML(),
        crossOrigin: 'anonymous'
      })
    });
    vectorLayer.set('name', fileName);
    vectorLayer.setOpacity(0.7);
    this.basemapService.getCurrentBasemap().addLayer(vectorLayer);
    // this.setUploadStatusInPercentage(80);
    this.addLayerObjectsToTower();
    setTimeout(() => {
      this.basemapService.getCurrentBasemap().getLayers().forEach(currentLayer => {
        if (fileName === currentLayer.values_.name) {
          const extentValue = currentLayer.values_.source.getExtent();
          console.log('kml/kmz extend value is ', extentValue, currentLayer.values_.source);
          this.basemapService.getCurrentBasemap().getView().fit(extentValue);
          // this.setUploadStatusInPercentage(100);
          this.basemapService.getCurrentBasemap().getView().setZoom(this.basemapService.getCurrentBasemap().getView().getZoom() - 1);
        }
      });
    }, 5000);

    /* const vector = new HeatmapLayer({
      source: new VectorSource({
        url: kmlURL,
        format: new KML({
          extractStyles: false,
        }),
      }),
      blur: parseInt('5', 10),
      radius: parseInt('5', 10),
      weight: function (feature) {
        // 2012_Earthquakes_Mag5.kml stores the magnitude of each earthquake in a
        // standards-violating <magnitude> tag in each Placemark.  We extract it from
        // the Placemark's name instead.
        const name = feature.get('name');
        const magnitude = parseFloat(name.substr(2));
        return magnitude - 5;
      },
    });
    this.basemap.addLayer(vector); */
  }

  addwmsLayer(url, jsonObject): any {
    console.log(url, jsonObject);
    const wmsSource = new tiles({
      url,
      projection: this.basemapService.getBaseMapProjection(),
      serverType: 'geoserver',
      extent: jsonObject.metadata
    });
    const wmsLayer = new OlTileLayer({
      extent: jsonObject.metadata,
      source: wmsSource,
      name: jsonObject.fileName
    });
    console.log(wmsSource);
    console.log(wmsLayer);
    wmsLayer.set('name', jsonObject.fileName);
    wmsLayer.set('type', 'URL');
    this.basemapService.getCurrentBasemap().addLayer(wmsLayer);
    if (jsonObject.metadata !== undefined) {
      this.basemapService.getCurrentBasemap().getView().fit(jsonObject.metadata);
      this.basemapService.getCurrentBasemap().getView().setZoom(this.basemapService.getCurrentBasemap().getView().getZoom() - 1);
    }
    /* this.basemapService.getCurrentBasemap().getLayers().forEach(currentLayer => {
      if (jsonObject.fileName === currentLayer.values_.name) {
        const extentValue = currentLayer.values_.source.getImageExtent();
        this.basemapService.getCurrentBasemap().getView().fit(extentValue);
      }
    }); */
  }

  private randomRainbowColor(min, max): any {
    return this.Colors[Math.floor(Math.random() * (max - min + 1)) + min];
  }

  private addLayerObjectsToTower(): any {
    console.log(this.layerObj, "check the layer object.....")
    this.geotowerService.clientObjList.push(this.layerObj);
    this.geotowerService.geotowerLayersList.push(this.layerObj);
  }


  filterMapTypes(mapType, mapRealname): any {
    // const mapType = mapTypeObj.value;
    // console.log('what is map type: ', mapType, this.isBaseMapOptActive);
    // this.mapTypeName = mapType;
    // this.selecteMapType = mapTypeObj;
    this.authObsr.updateBaseLayerName(mapRealname);
    // this.isBaseMapOptActive = !this.isBaseMapOptActive;
    // #TODO - later this code need to be simply & easly...
    this.basemapService.getCurrentBasemap().getLayers().forEach(layer => {
      console.log('layer name ', mapType, layer.values_.name, layer.getVisible(), layer);
      if (mapType === 'openstreet') {
        if (layer.values_.name === 'satellite' || layer.values_.name === 'terrain'
          || layer.values_.name === 'toner' || layer.values_.name === 'bingsatellite'
          || layer.values_.name === 'bingstreet' || layer.values_.name === 'googlestreet' 
          || layer.values_.name === 'googlesatellite' || layer.values_.name === 'USGSTopo'
          || layer.values_.name === 'USTopo') {
          layer.setVisible(false);
        } else if (layer.values_.name === 'openstreet') {
          layer.setVisible(true);
        }
      }
      if (mapType === 'satellite') {
        if (layer.values_.name === 'openstreet' || layer.values_.name === 'terrain'
          || layer.values_.name === 'toner' || layer.values_.name === 'bingsatellite'
          || layer.values_.name === 'bingstreet' || layer.values_.name === 'googlestreet' 
          || layer.values_.name === 'googlesatellite'  || layer.values_.name === 'USGSTopo'
          || layer.values_.name === 'USTopo') {
          layer.setVisible(false);
        } else if (layer.values_.name === 'satellite') {
          layer.setVisible(true);
        }
      }
      if (mapType === 'terrain') {
        if (layer.values_.name === 'satellite' || layer.values_.name === 'openstreet'
          || layer.values_.name === 'toner' || layer.values_.name === 'bingsatellite'
          || layer.values_.name === 'bingstreet' || layer.values_.name === 'googlestreet' 
          || layer.values_.name === 'googlesatellite'  || layer.values_.name === 'USGSTopo'
          || layer.values_.name === 'USTopo') {
          layer.setVisible(false);
        } else if (layer.values_.name === 'terrain') {
          layer.setVisible(true);
        }
      }

      if (mapType === 'toner') {
        if (layer.values_.name === 'satellite' || layer.values_.name === 'openstreet'
          || layer.values_.name === 'terrain' || layer.values_.name === 'bingsatellite'
          || layer.values_.name === 'bingstreet' || layer.values_.name === 'googlestreet' 
          || layer.values_.name === 'googlesatellite'  || layer.values_.name === 'USGSTopo'
          || layer.values_.name === 'USTopo') {
          layer.setVisible(false);
        } else if (layer.values_.name === 'toner') {
          layer.setVisible(true);
        }
      }

      if (mapType === 'bingsatellite') {
        if (layer.values_.name === 'satellite' || layer.values_.name === 'openstreet'
          || layer.values_.name === 'terrain' || layer.values_.name === 'toner'
          || layer.values_.name === 'bingstreet' || layer.values_.name === 'googlestreet' 
          || layer.values_.name === 'googlesatellite'  || layer.values_.name === 'USGSTopo'
          || layer.values_.name === 'USTopo') {
          layer.setVisible(false);
        } else if (layer.values_.name === 'bingsatellite') {
          layer.setVisible(true);
        }
      }
      // New code for street view of bing
      if (mapType === 'bingstreet') {
        if (layer.values_.name === 'openstreet' || layer.values_.name === 'terrain'
          || layer.values_.name === 'toner' || layer.values_.name === 'bingsatellite'
          || layer.values_.name === 'googlestreet' || layer.values_.name === 'satellite' 
          || layer.values_.name === 'googlesatellite'  || layer.values_.name === 'USGSTopo'
          || layer.values_.name === 'USTopo') {
          layer.setVisible(false);
        } else if (layer.values_.name === 'bingstreet') {
          layer.setVisible(true);
        }
      }

      // New code for street view of bing
      if (mapType === 'googlestreet') {
        if (layer.values_.name === 'openstreet' || layer.values_.name === 'terrain'
          || layer.values_.name === 'toner' || layer.values_.name === 'bingsatellite'
          || layer.values_.name === 'bingstreet' || layer.values_.name === 'satellite' 
          || layer.values_.name === 'googlesatellite'  || layer.values_.name === 'USGSTopo'
          || layer.values_.name === 'USTopo') {
          layer.setVisible(false);
        } else if (layer.values_.name === 'googlestreet') {
          layer.setVisible(true);
        }
      }

      // New code for satellite view of google
      if (mapType === 'googlesatellite') {
        if (layer.values_.name === 'openstreet' || layer.values_.name === 'terrain'
          || layer.values_.name === 'toner' || layer.values_.name === 'bingsatellite'
          || layer.values_.name === 'bingstreet' || layer.values_.name === 'satellite' 
          || layer.values_.name === 'googlestreet'  || layer.values_.name === 'USGSTopo'
          || layer.values_.name === 'USTopo') {
          layer.setVisible(false);
        } else if (layer.values_.name === 'googlesatellite') {
          layer.setVisible(true);
        }
      }
      // New code for usgstopo
      if (mapType === 'USGSTopo') {
        if (layer.values_.name === 'openstreet' || layer.values_.name === 'terrain'
          || layer.values_.name === 'toner' || layer.values_.name === 'bingsatellite'
          || layer.values_.name === 'bingstreet' || layer.values_.name === 'satellite' 
          || layer.values_.name === 'googlestreet'  || layer.values_.name === 'googlesatellite'
          || layer.values_.name === 'USTopo') {
          layer.setVisible(false);
        } else if (layer.values_.name === 'USGSTopo') {
          layer.setVisible(true);
        }
      }
      // New code for ustopo
      if (mapType === 'USTopo') {
        if (layer.values_.name === 'openstreet' || layer.values_.name === 'terrain'
          || layer.values_.name === 'toner' || layer.values_.name === 'bingsatellite'
          || layer.values_.name === 'bingstreet' || layer.values_.name === 'satellite' 
          || layer.values_.name === 'googlestreet'  || layer.values_.name === 'googlesatellite'
          || layer.values_.name === 'USGSTopo') {
          layer.setVisible(false);
        } else if (layer.values_.name === 'USTopo') {
          layer.setVisible(true);
        }
      }
    });
  }
  showalert() {
    alert("Subscribe for a higer package to use this feature")
  }
}
